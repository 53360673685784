export const projectName = 'UnixoBank';

export function getApiDomain() {
	let hostname = getDomain(location.hostname);
	let protocol = location.protocol;
	switch (process.env.NODE_ENV) {
		case 'dev':
		case 'development':
			hostname = 'payment-dev.com';
			protocol = 'https:';
			break;
	}
	if (window.location.href.includes('test')) {
		hostname = 'test.payment-dev.com';
		protocol = 'http:';
	}
	let domain = `${protocol}//api.${hostname}`;
	// let domain = 'https://api.unixobank.com';
	return domain;
}

export function getWssDomain() {
	let hostname = getDomain(location.hostname);
	let protocol = 'wss:';
	let subdomainName = 'api';
	if (hostname === 'unixobank.com') {
		subdomainName = 'wss';
	} else {
		hostname = 'payment-dev.com';
	}
	if (window.location.href.includes('test')) {
		subdomainName = 'api.test';
		protocol = 'ws:';
	}
	// switch (process.env.NODE_ENV) {
	//   case 'dev':
	//   case 'development':
	//     hostname = 'payment-dev.com';
	//     subdomainName = 'api';
	//     break;
	//   case 'production':
	//     hostname = 'payment-dev.com';
	//     subdomainName = 'api';
	//     break;
	// }
	let domain = `${protocol}//${subdomainName}.${hostname}`;
	return domain;
}

export function getExqueryDomain() {
	let hostname = getDomain(location.hostname);
	let protocol = location.protocol;
	switch (process.env.NODE_ENV) {
		case 'dev':
		case 'development':
			hostname = 'payment-dev.com';
			protocol = 'https:';
			break;
	}
	let domain = `https://exq.unixobank.com`;
	return domain;
}
export function getQueryDomain() {
	let hostname = getDomain(location.hostname);
	let protocol = location.protocol;
	switch (process.env.NODE_ENV) {
		case 'dev':
		case 'development':
			hostname = 'payment-dev.com';
			protocol = 'https:';
			break;
	}
	let domain = `https://query.unixobank.com`;
	return domain;
}

function getDomain(hostname) {
	return hostname.substring(hostname.lastIndexOf('.', hostname.lastIndexOf('.') - 1) + 1);
}

export function getOrigin() {
	let hostname = getDomain(location.hostname);
	let protocol = location.protocol;
	let manager = 'manager';
	switch (process.env.NODE_ENV) {
		case 'dev':
			hostname = 'unixobank.com';
		case 'development':
			hostname = 'payment-dev.com';
			protocol = 'https:';
			break;
	}
	if (window.location.href.includes('test')) {
		hostname = 's3-website.ap-east-1.amazonaws.com';
		protocol = 'http:';
		manager = 'payment-test-manager';
	}
	let domain = `${protocol}//${manager}.${hostname}`;
	return domain;
}
export function getMerchantOrigin() {
	let hostname = getDomain(location.hostname);
	let protocol = location.protocol;
	let merchant = 'merchant';
	switch (process.env.NODE_ENV) {
		case 'dev':
			hostname = 'unixobank.com';
		case 'development':
			hostname = 'payment-dev.com';
			protocol = 'https:';
			break;
	}
	if (window.location.href.includes('test')) {
		hostname = 's3-website.ap-east-1.amazonaws.com';
		protocol = 'http:';
		merchant = 'payment-test-merchant';
	}
	let domain = `${protocol}//${merchant}.${hostname}`;
	return domain;
}
export function getAgentOrigin() {
	let hostname = getDomain(location.hostname);
	let protocol = location.protocol;
	let agent = 'agent';
	switch (process.env.NODE_ENV) {
		case 'dev':
			hostname = 'unixobank.com';
		case 'development':
			hostname = 'payment-dev.com';
			protocol = 'https:';
			break;
	}
	if (window.location.href.includes('test')) {
		hostname = 's3-website.ap-east-1.amazonaws.com';
		protocol = 'http:';
		agent = 'payment-test-agent';
	}
	let domain = `${protocol}//${agent}.${hostname}`;
	return domain;
}
